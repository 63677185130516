.top-nav {
  display: flex;
  white-space: nowrap;
  width: 100%;
  align-items: stretch;
}

.top-nav a {
  padding: 15px 30px;
}

.top-nav a.active {
  padding: 15px 30px 12px 30px;
  border-bottom: 3px solid red;
}

.small-text {
  font-size: smaller;
}

.upcoming-events-section {
  background-color: rgba(238, 48, 34, 0.05);
}

.red-line {
  border-bottom: 1px solid pink;
}