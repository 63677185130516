.line1 {
  width: 100%;
  padding: 3px 0px;
  background-color: red;
  border-radius: 10px;
}

.line2 {
  width: 75%;
  padding: 3px 0px;
  background-color: red;
  border-radius: 10px;
}