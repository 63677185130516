.full-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header-container {
  display: flex;
  flex-direction: column;
}

.hero-container {
  background-color: #0D131A;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cta {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: left;
  background-color: #0D131A;
  color: #ffffff;
  /* background-image: url('../../assets/images/big-bubble.png'); */
  background-repeat: no-repeat;
  background-position: center right;
}

.heading {
  font-family: 'Abril Fatface', cursive;
  font-size: 3.5em;
}

.subheading, p {
  font-size: 1.3em;
}

/* .section1-container {
  background-image: url('../../assets/images/Awareness1.png');
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
} */

/* @media screen and (min-width: 1400px) {
  .section1 {
    padding: 270px 0px;
  }
} */

.complaints {
  background-image: url('../../assets/images/Complaints.png');
  background-size: contain;
  background-position: center right;
  background-repeat: no-repeat;
}

table {
  border-collapse: collapse;
}

td, th {
  border: 1px solid red;
}